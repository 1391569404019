<template>
  <div>
    <c-search-box ref="searchbox" :isShowSearch="false" @enter="getList">
      <template slot="search">
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" @datachange="getList"  />
        </div>
        <div class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <!-- 보충작업 -->
          <c-select
            type="search"
            codeGroupCd="SOP_SUPPLEMENT_WORK_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="supplementWorkTypeCd"
            label="보충작업"
            v-model="searchParam.supplementWorkTypeCd"
            @datachange="getList"
            ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <!-- 작업기간 -->
          <c-datepicker
            :range="true"
            defaultStart="-6M"
            defaultEnd="6M"
            label="작업기간"
            name="permitDates"
            v-model="searchParam.permitDates"
            @datachange="getList"
          />
        </div>
          <!-- 허가서구분 -->
        <!-- <div v-if="!popupParam.permitTypeCd" class="col-xs-6 col-sm-6 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="SOP_PERMIT_TYPE_CD"
            itemText="codeName"
            itemValue="code"
            name="permitTypeCd"
            label="허가서구분"
            v-model="searchParam.permitTypeCd"
          ></c-select>
        </div> -->
      </template>
    </c-search-box>
    <c-table
      ref="swpTable"
      title="작업허가서 목록"
      tableId="swp01"
      :columns="grid.columns"
      :filtering="false"
      :data="grid.data"
      :merge="grid.merge"
      rowKey="sopWorkPermitId"
      :selection="popupParam.type"
      @linkClick="linkClick"
    >
    </c-table>
    
    <!-- 버튼 영역 -->
    <div class="search-box-btn">
      <q-btn-group outline >
        <c-btn label="선택" icon="check" color="teal-5" @btnClicked="select" />
      </q-btn-group>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'safe-work-permit-pop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
          regUserId: ''
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        permitDates: [],
        applicationDeptCd: null,
        issuedDeptCd: null,
        permitTypeCd: null,
        supplementWorkTypeCd: null,
        swpStepCd: null,
      },
      grid: {
        columns: [
          {
            name: 'workSummary',
            field: 'workSummary',
            label: '작업개요',
            align: 'left',
            sortable: true,
            type: 'link',
            style: 'width:50%',
          },
          {
            name: 'permitDate',
            field: 'permitDate',
            label: '허가일',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'applicationDeptUserName',
            field: 'applicationDeptUserName',
            label: '신청자',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          {
            name: 'issuedDeptUserName',
            field: 'issuedDeptUserName',
            label: '발급자',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
        ],
        data: [],
      },
      editable: true,
      listUrl: '',
      popupOptions: {
        isFull: true,
        target: null,
        title: '',
        visible: false,
        param: {},
        closeCallback: null,
      },
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.swp.workPermit.list.url
      // code setting
      this.searchParam.vendorCd = this.$store.getters.user.vendorCd

      if (this.popupParam.plantCd) {
        this.searchParam.plantCd = this.popupParam.plantCd
      }
      if (this.popupParam.regUserId) {
        this.searchParam.regUserId = this.popupParam.regUserId
      }
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      this.popupOptions.title = '작업허가서';
      this.popupOptions.param = {
        sopWorkPermitId: row ? row.sopWorkPermitId : '',
        permitTypeCd: 'SPT0000001',
      };
      this.popupOptions.target = () => import(`${'./safeWorkPermitDetail.vue'}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    select() {
      let selectData = this.$refs['swpTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: '안내', // 안내
          message: '작업허가서를 지정하세요.', // 작업허가서를 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
  }
};
</script>
